import { Link, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import bellIcon from '../../assets/icons/notifications.svg';
import styles from './header.module.scss';
import { useContext, useEffect, useState } from 'react';
import { USER_CONTEXT } from '@orascom/broker-sales-man-common-components';
import { NOTIFICATIONS_CONTEXT } from '../../contexts/notifications-context';
import {
  LanguageDropdown,
  SelectDropdown,
  Tooltip,
} from '@orascom/common-components';
import { CURRENCIES, CurrencyContext } from '@orascom/utils';
import { CURRENCIES_ENUM } from '@orascom/api-interfaces';
import InfoIcon from '../../assets/icons//info.svg?react';
export interface HeaderProps {
  notificationPath: To;
  portal: 'Broker' | 'Sales Man';
}
export function Header(props: Readonly<HeaderProps>) {
  const { t } = useTranslation();
  const { isRead } = useContext(NOTIFICATIONS_CONTEXT);
  const { user } = useContext(USER_CONTEXT);
  const [showCurrencyTooltip, setShowCurrencyTooltip] = useState(false);

  const { setActiveCurrency, disableCurrencySetting } =
    useContext(CurrencyContext);

  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem('currency') as string) || {
      label: CURRENCIES_ENUM.EGP,
      value: 0,
    }
  );
  useEffect(() => {
    if (disableCurrencySetting.disabled) {
      setSelectedCurrency({
        label: CURRENCIES_ENUM.USD,
        value: 1,
      });
    }
  }, [disableCurrencySetting.disabled]);

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(selectedCurrency));
  }, [selectedCurrency]);

  const currencyOptions = CURRENCIES.map((cur, ind) => ({
    label: cur,
    value: ind,
  }));


  return (
    <div className={styles['wrapper']}>
      {props.portal === 'Broker' && (
        <>
          <LanguageDropdown />
          <div className={styles['currency']}>
            <SelectDropdown
              options={currencyOptions}
              placeholder={selectedCurrency.label}
              defaultValue={selectedCurrency}
              onChange={(val) => {
                if (val) {
                  setActiveCurrency(val.label);
                  setSelectedCurrency(val);
                }
              }}
              isSearchable={false}
              disabled={disableCurrencySetting.disabled}
              selectedOption={selectedCurrency}
            />
            {disableCurrencySetting.info && (
              <div className="tooltip__container">
                <button onClick={() => setShowCurrencyTooltip(true)}>
                  <InfoIcon />
                </button>
                {showCurrencyTooltip && (
                  <Tooltip
                    position="bottom"
                    onClose={() => setShowCurrencyTooltip(false)}
                  >
                    <span>{disableCurrencySetting.info}</span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <h1 className={styles['title']}>
        {t('hello')} {user?.name},
      </h1>
      <Link to={props.notificationPath} className={styles['notifications']}>
        {!isRead && <span className={styles['circle']} />}
        <img src={bellIcon} alt="" role="presentation" />
      </Link>
    </div>
  );
}

export default Header;
